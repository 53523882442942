import { ref, watch } from '@vue/runtime-core'
import { log } from './log'

export const userCountry = ref<string>('DE')

const dataLayer =
    globalThis.window ?
      globalThis.window.dataLayer = globalThis.window.dataLayer || [] :
        []

const gtag = globalThis.window?.gtag || function gtag() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments)
}

await fetch('/country').then(async (res) => {
  if (!res.headers.get('content-type')?.includes('json')) return userCountry.value = 'DE'
  const data = await res.json()
  if (data && 'country' in data) userCountry.value = data.country.toUpperCase() === 'AT' ? 'AT' : 'DE'
  log(data)
})

watch(userCountry, (userCountry) => {
  gtag('set', { country: userCountry, currency: 'EUR' })
  log('Country changed to', userCountry)
})
