<!--==================================================================+
| MODULE-COMPONENT: DISH CARD                                         |
+===================================================================-->
<template>
  <div ref="card" class="tile dish-card snap-start" :class="{ soldout: product.soldout }">
    <a
      :href="`/${$i18n.locale}/${$i18n.locale === 'de' ? 'gerichte' : 'dishes'}/${product.slug}`"
      :aria-label="product.title"
      class="relative block shadow-md"
      @click.stop.prevent="productClicked(product)"
    >
      <div v-if="product.soldout" class="soldout-info">
        {{ $t('dish.soldout') }}
      </div>
      <div class="image-overlay">
        <juit-button class="details-button">
          {{ $t( 'dish.details' ) }}
        </juit-button>
      </div>
      <juit-image
        class="dish-img"
        ratio="1x1"
        :alt="product.title"
        :src="product.image.src"
      />
      <div v-if="product.specialTag" class="tags absolute top-0 right-0">
        <juit-badge :class="[ product.specialTag, 'smaller']">
          <juit-tag-icon :cat="product.specialTag" class="mr-2" />
          <span>{{ product.specialTag }}</span>
        </juit-badge>
      </div>
    </a>
    <div class="dish-details">
      <p class="title">
        {{ product.title }}
      </p>
      <div class="nutritions">
        <div class="single-nutrition">
          {{ nutrition(product.calories, '', product.weight / (product.portions || 1), 1) }}<br><p>
            {{ $t( 'dish.calories' ) }}
          </p>
        </div>
        <div class="single-nutrition">
          {{ nutrition(product.protein, 'g', product.weight / (product.portions || 1), 1) }}<br><p>
            {{ $t( 'dish.protein' ) }}
          </p>
        </div>
        <div class="single-nutrition">
          {{ nutrition(product.carb, 'g', product.weight / (product.portions || 1), 1) }}<br><p>
            {{ $t( 'dish.carbohydrates' ) }}
          </p>
        </div>
        <div class="single-nutrition">
          {{ nutrition(product.fat, 'g', product.weight / (product.portions || 1), 1) }}<br><p>
            {{ $t( 'dish.fat' ) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { Dish } from '../content'
  import { nutrition } from './juit-nutrition-label.vue'
  import dishModal from '../modals/dish.vue'
  import juitBadge from './juit-badge.vue'
  import juitTagIcon from './juit-tag-icon'

  export default defineComponent({
    components: { juitBadge, juitTagIcon },
    props: {
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
      cta: {
        type: Boolean,
        default: true,
      },
      // If in carousel
      index: {
        type: Number,
        default: NaN,
      },
    },
    emits: [ 'cardwidth' ],

    data: () => ({
      nutrition: nutrition,
    }),

    // Update card width on product change
    watch: {
      product: {
        handler() {
          if (this.index === 0) this.$nextTick(() => this.getCardWidth())
        },
        immediate: true,
        deep: true,
      },
    },

    // And on resize
    mounted() {
      if (this.index === 0) {
        window.addEventListener('resize', () => this.getCardWidth(300))
      }
    },
    unmounted() {
      if (this.index === 0) {
        window.removeEventListener('resize', () => this.getCardWidth)
      }
    },

    methods: {
      // Trigger product modal instead of following product link
      productClicked(product: Dish) {
        const url = `/${this.$i18n.locale}/${this.$i18n.locale === 'de' ? 'gerichte' : 'dishes'}/${product.slug}`
        this.$createModal(dishModal as any, { product, url, cta: this.cta }).then((url) => {
          if (url) this.$router.push(url)
        })
      },

      // Emit its width to the carousel
      getCardWidth(delay = 0) {
        setTimeout(() => {
          const card = this.$refs.card as HTMLElement
          if (card) this.$emit('cardwidth', card.getBoundingClientRect().width)
        }, delay)
      },
    },
  })
</script>

<style scoped lang="pcss">
.tile.dish-card {
  @apply w-full w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5;
  @apply flex flex-col;
  @apply pt-1 px-1 relative;
  &.soldout {
    .image-container:after {
      content: '';
      @apply absolute w-full h-full bg-gray-700 opacity-70 z-1 top-0;
    }
  }
  .soldout-info {
    @apply absolute font-bold transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-10;
    @apply text-white text-2xl;
  }
  .image-overlay {
    @apply absolute w-full h-full flex flex-col justify-center bg-white bg-opacity-50;
    @apply z-10 transition-all opacity-0;
    .details-button {
      @apply mx-auto;
    }
  }
  .dish-details {
    @apply h-full w-full text-center mt-2 md:mt-3 text-xl z-10 relative min-h-14;
    .nutritions {
      @apply w-full flex justify-center opacity-0;
      @apply absolute z-10 top-0 left-0;
      @apply transition-opacity duration-300 pointer-events-none;
      @apply leading-tight;
      .single-nutrition {
        @apply self-center px-3 font-bold;
        p {
          @apply font-normal text-base;
        }
      }
    }
    .title {
      @apply uppercase transition-opacity opacity-100 duration-300;
      @apply text-sm sm:text-base md:text-lg text-left;
    }
  }
  &:hover {
    .image-overlay {
      @apply md:opacity-100;
    }
    .dish-details {
      .title {
        @apply md:opacity-0;
      }
      .nutritions {
        @apply md:opacity-100;
      }
    }
  }
}

</style>
