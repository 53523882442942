<template>
  <div class="modal no-scrollbar" :class="{ closing }">
    <div
      class="background bg-[#2e2e2eb3]"
      @click="onClick"
      @animationend="() => closing ? $emit('dismissModal') : undefined"
    />
    <div class="modal-container">
      <div class="content">
        <div class="padding">
          <slot />
        </div>

        <div v-if="$slots.footer" class="footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, warn } from 'vue'

  export default defineComponent({
    name: 'JuitModal',
    props: {
      locked: {
        type: Boolean,
        required: false,
        default: false,
      },
      url: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: [ 'dismissModal' ],
    data: () => ({
      closing: false,
      pushState: false,
    }),

    mounted() {
      document.addEventListener('keyup', this.onKeyUp)
      window.addEventListener('popstate', this.onPopState)

      if (this.locked && this.url) {
        warn('Ignoring push state with URL for locked modal')
      } else if (! this.locked) {
        const href = this.url ? new URL(this.url, window.location.href).href : undefined
        window.history.pushState({ }, '', href)
        this.pushState = true
      }
    },
    methods: {
      // Used from _outside_ of this class to _forcedly_ dismiss the modal
      closeModal() {
        // First of all, remove our event listeners... we're done
        document.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('popstate', this.onPopState)

        // Pop state if we have to, "onPopState" below won't be called
        if (this.pushState) {
          window.history.go(-1)
          this.pushState = false
        }

        // Gracefully close this modal, "onAnimationEnd" will dismiss it
        this.closing = true
      },

      /* -------------------------------------------------------------------- *
       * EVENT HANDLERS                                                       *
       * -------------------------------------------------------------------- */
      // Clicks from the modal background
      onClick(event: MouseEvent) {
        if (! this.locked) this.closeModal()
      },

      // Key up from the window, we trap "Escape"
      onKeyUp(event: KeyboardEvent) {
        if (event.key !== 'Escape') return // do nothing if key is not "Escape"
        if (! this.locked) this.closeModal()
      },

      // Pop state, in result of our push (if we have an URL) or if going back
      // in the history to a previous page (before the modal was emitted)
      onPopState(event: PopStateEvent) {
        this.pushState = false
        this.closeModal()
      },
    },
  })
</script>

<style scoped lang="pcss">
  .modal {
    @apply fixed;
    @apply top-0 left-0 right-0 bottom-0;
    @apply flex;
    @apply z-1000;
    @apply overflow-scroll;

    /* Background: gray curtain behind all elements */

    .background {
      @apply fixed transition w-full h-screen top-0 left-0;
      @apply opacity-0 animate-fadein;
    }

    &.closing .background {
      @apply opacity-100 animate-fadeout;
    }

    /* Container: centers ".content" vertically */

    .modal-container {
      @apply py-1 px-1 md:px-0 md:py-10 w-full m-auto items-center;
    }

    /* Content: vertically-centered full width container */

    .content {
      @apply opacity-0 transition overflow-hidden relative bg-ash mx-auto w-full flex flex-col mx-auto;
      @apply md:max-w-4xl; /* overridden when ".modal" is ".small" below */
      @apply animate-slideintop;
    }

    &.small .content {
      @apply md:max-w-xl;
    }

    &.closing .content {
      @apply animate-slideouttop opacity-100;
    }

    /* Padding: pads content */

    .padding {
      @apply py-5 px-5 sm:px-7 sm:pb-7 sm:pt-6 md:px-8 md:pb-8 md:pt-7;
    }

    &.no-padding .padding {
      @apply py-0 px-0;
    }

    /* Footer: optional footer for the modal */

    .footer {
      @apply py-2 border-t border-gray-300 px-5 sm:px-7 sm:py-3 md:py-5 md:px-8 w-full;
    }
  }

</style>
