<!-- eslint-disable vue/no-v-html -->
<!--==================================================================+
| MODULE-COMPONENT: BOX LIST                                          |
+===================================================================-->

<template>
  <div
    v-if="module"
    ref="boxlist"
    class="container boxes"
  >
    <dish-preferences :active="show_preferences" always-open @to_boxes="pref_selected = true" />
    <div class="box-selection" :class="{ show_preferences }">
      <div class="text-top">
        <h1>{{ module.title }}</h1>
        <p class="subheading" v-html="module.description" />
        <a :href="`/${$i18n.locale}/faq?target=delivery-0`" target="_blank" class="text-xs md:text-sm text-black opacity-60 mt-1 -mb-0.5 hover:underline">
          {{ $t( 'boxes.legal-text' ) }}
        </a>
      </div>

      <!--==================================================================+
      | CHOOSE BOX                                                          |
      +===================================================================-->
      <div
        class="boxes-list-wrapper mobile_open"
      >
        <div class="tiled">
          <div
            v-for="(box, $index) in module.list"
            :key="$index"
            class="tile"
            :class="[
              { selected: box.$id === stored_box_id },
              box.label,
              { '!hidden': (box.size === 12 || box.size === 8)}
            ]"
            :style="`--tw-delay: ${$index + 1}`"
            @click="chooseBox(box)"
          >
            <div class="img-placeholder" />
            <div class="box-text" :class="`box-${box.size}`">
              <div class="icon-wrapper">
                <boxIcon class="box-icon" />
              </div>
              <span class="box-title">{{ box.title }}</span>
              <p>{{ box.description }}</p>
              <div class="label-wrapper">
                <div v-if="box.label" class="box-label">
                  <span class="whitespace-nowrap sm:pt-[1px]">{{ $t(`boxes.label-${box.label}`) }}</span>
                  <percentage v-if="box.label === 'bestvalue'" /><heart v-if="box.label === 'popular'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--==================================================================+
      | CONTINUE TO ORDER                                                   |
      +===================================================================-->
      <div
        class="to-order active"
      >
        <juit-button
          :disabled="!box_selected"
          class="to-dishes"
          @click="toOrder()"
        >
          <span class="cta-btn-text">{{ $t( 'pre-checkout.to-order' ) }}</span>
        </juit-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { BoxList, Box } from '../content/convert'
  import { defineComponent, PropType } from 'vue'
  import { analyticsEvent } from '../analytics'
  import { reactiveCart } from '../init/reactive-order'
  import dishPreferences from '../components/dish-preferences.vue'
  import {
    box as boxIcon,
    heart as heart,
    percentage as percentage,
  } from '../assets/async'

  export default defineComponent({
    components: {
      boxIcon,
      heart,
      percentage,
      dishPreferences,
    },
    props: {
      module: {
        type: Object as PropType<BoxList>,
        default: null,
      },
    },
    data: () => ({
      box_selected: undefined as Box | undefined,
      list_focused: false,
      pref_selected: false,
      boxIcon,
    }),

    computed: {
      stored_box_size() {
        return reactiveCart.box
      },
      stored_box_id() {
        return reactiveCart.boxId
      },
      list_box_id() {
        return this.module.list.map((box) => box.$id)
      },
      list_item_selected() {
        return this.list_box_id.includes(this.stored_box_id)
      },
      show_preferences() { // New users
        return this.module.showPref && !this.pref_selected && (!this.user || !this.user.referral_code)
      },
    },

    /* ========================================================================== *
     * Use stored box size                                                        *
     * -------------------------------------------------------------------------- */
    watch: {
      stored_box_size: {
        handler(box) {
          if (!this.box_selected || box !== this.box_selected.size) {
            const box_list = this.module.list || []
            if (box_list.length && reactiveCart.box) this.box_selected = box_list.find((box) => box.size === reactiveCart.box)
          }
        },
        immediate: true,
      },

      list_item_selected(selected) {
        if (!selected) this.list_focused = false
      },
    },

    methods: {
      /* ========================================================================== *
       * Select a box                                                               *
       * -------------------------------------------------------------------------- */
      chooseBox(box: Box) {
        if (box.size < reactiveCart.total) reactiveCart.clearCart()
        this.list_focused = !this.list_focused
        reactiveCart.box = box.size
        reactiveCart.boxId = box.$id
        reactiveCart.b2b = false
        this.box_selected = box
        reactiveCart.updateServerCart()
      },

      toOrder() {
        // GA Event: Select box, either slug of preselected cart or box size
        const box_size = this.box_selected?.cta?.slug || reactiveCart.box
        analyticsEvent('select_box', { box_size })

        // Redirect to order _or_ to preselected cart
        const slug = this.box_selected?.cta?.slug || 'order'
        this.$router.push(`/${this.$i18n.locale}/${slug}`)
      },
    },
  })
</script>

<style scoped lang="pcss">
.box-selection {
  @apply opacity-100 max-h-max duration-500 overflow-hidden;
  &.show_preferences {
    @apply opacity-0 max-h-0;
  }
}
.boxes-list-wrapper {
  &.mobile_open .tiled .tile {
    @apply mb-2 lg:mb-0;
    .box-text {
      @apply opacity-100 min-h-24 max-h-24 sm:max-h-56 md:max-h-80 mobile-xs:py-2;
    }
    &.popular, &.bestvalue {
      @apply border-2;
    }
  }
  /* Desktop */
  .tiled {
    @apply mt-4 mb-0 mx-0 sm:-mx-1 justify-center;
    /* Single Box */
    .tile {
      @apply relative bg-gray-200 transition-all;
      @apply sm:min-h-20 overflow-hidden;
      @apply w-full md:w-auto;
      @apply flex md:flex-col sm:flex-margin-1/2 lg:flex-1 sm:max-w-[50%];
      @apply p-0 sm:mb-2 lg:mb-0 mx-auto sm:mx-1;
      @apply cursor-pointer;
      &.popular {
        @apply border-boxPopular sm:border-2;
        .box-label {
          @apply bg-boxPopular;
        }
      }
      &.bestvalue {
        @apply border-boxValue sm:border-2;
        .box-label {
          @apply bg-boxValue;
        }
      }

      /* update or remove this style after we have 6+ box options on prod */
      &:nth-child(5):last-child {
        @apply mobile:flex-grow-0 sm:flex-card-1/2 lg:flex-1;
      }

      .img-placeholder {
        @apply w-24 h-0 sm:h-48 xl:h-56 opacity-0 pointer-events-none;
      }

      /* Text content */
      .box-text {
        @apply flex flex-col sm:flex-1 sm:mt-1;
        @apply opacity-0 sm:opacity-100 min-h-0 max-h-0 sm:max-h-56 md:max-h-80 transition-all duration-200;
        @apply sm:text-center;
        @apply justify-center;
        @apply p-0 sm:py-2 sm:py-0;
        @apply sm:absolute left-1/2 top-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2;
        .icon-wrapper {
          @apply absolute sm:static left-2.5 sm:left-1/2 transform sm:-translate-x-1/2 sm:transform-none;
          @apply bg-transparent;
          @apply w-20 h-16 sm:w-32 sm:h-20 sm:w-auto sm:h-auto;
        }
        .label-wrapper {
          @apply sm:mt-1 sm:-mb-2 sm:h-[18px] h-auto;
          @apply font-semibold text-xs text-white flex justify-center;
          @apply absolute sm:relative right-1.5 sm:right-0 sm:top-0 sm:transform-none transform -translate-y-1/2 top-1/2;
          .box-label {
            @apply rounded h-full px-1.5 py-0.5 sm:py-0 flex justify-center flex-nowrap;
            svg {
              @apply w-2.5 ml-1;
            }
          }
        }
        :deep() svg.box-icon {
          @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:static sm:transform-none;
          @apply mx-auto;
          @apply w-12 sm:w-16;
        }
        .box-title {
          @apply px-0 sm:mt-2 xl:mt-3 text-left sm:text-center;
          @apply whitespace-nowrap font-bold text-3xl xl:text-5xl xl:mb-1 font-headline uppercase;
        }
        p {
          @apply px-0 text-left sm:text-center;
          @apply whitespace-nowrap text-lg lg:text-xl xl:text-2xl;
        }
      }

      /* Single Box, selected */
      &.selected {
        @apply bg-black text-white;
        .box-text {
          @apply opacity-100 min-h-24 max-h-24 sm:max-h-56 md:max-h-80 mobile-xs:py-2;
        }

        &.popular, &.bestvalue {
          @apply border-2;
        }
      }
    }
  }
}

.boxes {
  .preferences, .box-selection {
   @apply duration-0;
  }
  .boxes-list-wrapper .tiled .tile {
    .img-placeholder {
      @apply mobile-xs:w-16 mobile:-mr-2;
    }
    .box-text {
      @apply mobile-xs:flex-row mobile-xs:min-h-0 mobile-xs:py-4;
      .icon-wrapper {
        @apply mobile-xs:w-10 mobile-xs:h-9 mobile-xs:ml-0;
        svg {
          @apply mobile-xs:w-8;
        }
      }
      .box-title {
        @apply min-w-24;
      }
      p {
        @apply mobile-xs:self-center mobile-xs:text-base mobile-xs:ml-1;
      }
    }
  }
}

/* CTA Box to order page */
.to-order {
  @apply sm:max-h-0 mx-auto;
  @apply flex flex-col;
  @apply overflow-hidden transition-all delay-150 duration-700;
  @apply max-h-0 opacity-0;
  @apply md:w-1/2 max-w-md;
  &.active {
    @apply opacity-100 mt-3 sm:-mt-1 lg:mt-3 max-h-60 border-black;
    @apply mx-auto mobile-sm:w-full;
  }
  p, .subheading {
    @apply hidden sm:block text-lg;
  }
  .subheading {
    @apply font-semibold;
  }
  svg {
    @apply hidden sm:block mr-4 w-12 self-center;
  }
  .to-dishes {
    @apply m-0 sm:mt-3 sm:mx-auto;
  }
}
.accordion:not(.mobile_open) .accordion-header {
  @apply max-h-max;
}
</style>
