import type { Address, CreateAddress } from '@juitnow/api-addresses-v2'
import { ref } from '@vue/runtime-core'
import { OrderData } from '../content/graphQL'
import { Mandate, Method } from '@juitnow/api-payments'
import { DishSortingKey } from '../widgets/juit-tags-filter.vue'


/** The page scrolling status */
export const onScroll = ref(false)

/** The user cohort */
export const userCohort = ref(NaN)

/** The user preferred nutrition sorting */
export const nutriPref = ref('' as DishSortingKey | '' | 'default')

/** The magic link token */
export const magicLinkToken = ref('')

/** The create address object */
export const createAddress = ref({
  create_billing: undefined as CreateAddress | undefined,
  create_shipping: undefined as CreateAddress | undefined,
})

/** Checkout step */
export const checkoutStep = ref('')

/** Notification/Alert pop up box */
export const alertNotification = ref('')

/** The dish ratings */
export const dishRatings = ref(undefined as Record<string, number> | undefined)
export const dishRatingsMine = ref(undefined as Record<string, number> | undefined)

/** The user orders */
export const ordersList = ref([] as OrderData[])

/** The user shipping addresses */
export const userAddresses = ref([] as Address[])

/** The user mandates */
export const userMandates = ref([] as Mandate[])

/** The user available payment methods */
export const userPaymentMethods = ref([] as Method[])

/** The user favorite dishes */
export const favDishes = ref([] as string[])

/** Loading orderData (slot, address, ratings etc) */
export const orderDataBusy = ref([] as string[])

/** Server-side cart API status */
export const cartBusy = ref([] as string[])

/** Checkout API status */
export const checkoutBusy = ref([] as string[])

