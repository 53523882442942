<!--==================================================================+
| WIDGET: INPUT TEXT > validation: alphabet LATIN 1                   |
+===================================================================-->

<template>
  <div class="flex-grow">
    <input
      type="text"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :value="text"
      :class="{ 'valid-false': show_alert }"
      @input="$emit('update:text', ($event.target as HTMLTextAreaElement)?.value)"
      @focus="touched = false"
      @blur="touched = true"
    >
    <p v-if="valid === false" class="invalid-msg">
      {{ $t( 'authentication.use-latin-alphabet' ) }}
    </p>
    <p v-if="!valid && requirement && touched" class="invalid-msg">
      *{{ $t('contact.missing-field') }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'JuitInputText',
    props: {
      text: {
        type: String,
        default: '',
      },
      autocomplete: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      requirement: {
        type: Boolean,
        default: false,
      },
    },
    emits: [ 'update:text', 'update:valid', 'update:alert' ],
    data: () => ({
      valid: null as boolean | null,
      touched: false as boolean,
    }),
    computed: {
      show_alert() {
        return !this.valid && this.requirement && this.touched
      },
    },
    watch: {
      show_alert(value) {
        this.$emit('update:alert', value)
      },
      text: {
        handler() {
          // if the field is empty > return NULL
          if (!this.text || this.text.length < 1) this.valid = null

          // regex check the input. see details:
          // https://en.wikipedia.org/wiki/Basic_Latin_(Unicode_block)
          // https://en.wikipedia.org/wiki/Latin-1_Supplement
          else if (/[^0-9&'.\-,/()\u0020\u0041-\u005a\u0061-\u007a\u00c0-\u00ff]/.test(this.text)) this.valid = false

          // else > return TRUE
          else this.valid = true

          // then EMIT the result
          this.$emit('update:valid', this.valid)
        },
        immediate: true,
      },
    },
  })
</script>
