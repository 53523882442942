<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="{ b2b, soldout: product.soldout }" class="card">
    <a
      tabindex="-1"
      :href="`/${$i18n.locale}/${$i18n.locale === 'de' ? 'gerichte' : 'dishes'}/${product.slug}`"
      :aria-label="product.title"
      class="mobile-sm:min-w-[90px] mobile-sm:min-h-[112px]"
      @click.stop.prevent="productClicked(product)"
    >
      <div v-if="product.soldout" class="soldout-info">
        {{ $t('dish.soldout') }}
        <div v-if="product.soldoutInfo" class="extra" v-html="product.soldoutInfo" />
      </div>
      <juit-image
        :alt="product.title"
        :src="product.image.src"
      />
    </a>
    <div class="dish-details">
      <p class="title">
        {{ product.title }}
      </p>
      <p class="subheadline">
        {{ product.secondTitle }}
      </p>
      <div v-if="product.tags" class="tags">
        <div v-if="product.specialTag">
          <juit-badge :class="[ product.specialTag, 'small small-icon tiny mr-1 md:mr-1 mb-1']">
            <juit-tag-icon :cat="product.specialTag" class="mr-1" />
            <span :class="{ 'ml-0.5': product.specialTag === 'Sweets' }">{{ product.specialTag }}</span>
            <span v-if="product.specialDish">{{ `&nbsp;+${ b2b ? ( product.specialDish / 1.07).toFixed(2) : product.specialDish }€` }}</span>
          </juit-badge>
        </div>
        <juit-badge
          v-for="(detail, index) in tag_splitted"
          :key="index"
          class="revert tiny mr-1 mb-1"
          :class="[detail[0], detail[1], detail[2]]"
        >
          <juit-tag-icon v-if="detail[1] === 'icon'" :cat="detail[0]" :name="detail[2]" />
          <span>{{ $t(`tags.${detail[2]}`) }}</span>
        </juit-badge>
      </div>
      <div class="card-bottom">
        <div class="nutritions">
          <div>{{ nutrition(product.calories, '', product.weight / (product.portions || 1), 1) }}&nbsp;{{ $t( 'dish.calories' ) }}</div>
          <div>{{ nutrition(product.protein, 'g', product.weight / (product.portions || 1), 1) }}&nbsp;{{ $t( 'dish.protein' ) }}</div>
          <div>{{ nutrition(product.carb, 'g', product.weight / (product.portions || 1), 1) }}&nbsp;{{ $t( 'dish.carbohydrates' ) }}</div>
        </div>
        <div class="interactive-cart" :class="[{ b2b }, { disabled: add_disabled }]">
          <div v-if="max_error" class="invalid-msg absolute right-0 -top-7">
            {{ $t('order.max_error') }}
          </div>
          <div class="plus-action">
            <span
              class="minus"
              :class="[ count ? '' : 'hide' ]"
              @click="cart.removeDish(product.ean)"
            >–</span>
            <span class="count" :class="[ count ? '' : 'hide' ]">{{ count }}</span>
            <span class="plus" :class="[ count ? 'hide' : '' , { 'pointer-events-none' : add_disabled }]" @click="cart.addDish(product.ean, 1, b2b)">+</span>
          </div>
          <div class="add-remove">
            <span class="button" :class="{ disabled: add_disabled }" @click="cart.removeDish(product.ean, b2b)">–</span>
            <div v-if="b2b" class="border-l border-r border-transparent focus:border-gray-300">
              <input
                v-model="b2b_quantity"
                type="text"
                maxlength="3"
                class="z-0"
                inputmode="numeric"
                pattern="[0-9]"
                :disabled="product.soldout"
                @input="onInput($event as InputEvent, product.ean, Number(b2b_quantity))"
                @focus="($event.target as HTMLInputElement).select()"
                @blur="max_error = false"
                @keyup.enter.prevent="($event.target as HTMLInputElement).blur()"
              >
              <span class="display">{{ count }}</span>
            </div>
            <span v-else class="counter z-0">{{ count }}</span>
            <span class="button" :class="{ disabled: add_disabled }" @click="cart.addDish(product.ean, 1, b2b)">+</span>
          </div>
        </div>
      </div>
      <juit-favorite :product="product" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import dishModal from '../../modals/dish.vue'
  import juitBadge from '../../widgets/juit-badge.vue'
  import { splitTags } from '../../widgets/juit-tag-icon'
  import { nutrition } from '../../widgets/juit-nutrition-label.vue'
  import { b2bMax, boxOptions, boxOptionsB2B, ReactiveCart, reactiveCart } from '../../init/reactive-order'
  import { Dish } from '../../content'
  import juitTagIcon from '../../widgets/juit-tag-icon'
  import juitFavorite from '../../widgets/juit-favorite.vue'

  export default defineComponent({
    components: { juitBadge, juitTagIcon, juitFavorite },
    props: {
      b2b: {
        type: Boolean,
        default: false,
      },
      product: {
        type: Object as PropType<Dish>,
        required: true,
      },
    },
    data: (instance) => ({
      regex: /[0-9]|\./,
      b2b_quantity: 0,
      box_options: instance.b2b ? boxOptionsB2B: boxOptions,
      max_error: false,
    }),
    computed: {
      max_box() {
        return this.b2b ? b2bMax : this.box_options ? this.box_options[this.box_options.length - 1] : 0
      },
      cart(): ReactiveCart {
        return reactiveCart
      },
      tag_splitted() {
        return Object.values(splitTags(this.product.tags))
      },
      products_in_cart() {
        return reactiveCart.dishes
      },
      count() {
        if (!this.products_in_cart.length) return 0
        return this.products_in_cart.find((product) => product.ean === this.product.ean)?.quantity || 0
      },
      add_disabled() {
        return this.product.soldout || (!this.b2b && this.cart.total === this.max_box)
      },
    },

    watch: {
      count(value) {
        if (this.b2b_quantity !== value) this.b2b_quantity = value
      },
    },

    mounted() {
      this.b2b_quantity = this.products_in_cart.find((dish) => dish.ean === this.product.ean)?.quantity || 0
    },

    methods: {
      nutrition: nutrition,
      /* ========================================================================== *
       * Trigger product modal instead of following product link                    *
       * -------------------------------------------------------------------------- */
      productClicked(product: Dish) {
        const url = `/${this.$i18n.locale}/${this.$i18n.locale === 'de' ? 'gerichte' : 'dishes'}/${product.slug}`
        this.$createModal(dishModal as any, { product, url, cta: false, b2b: this.b2b }).then((url) => {
          if (url) this.$router.push(url)
        })
      },

      /* ========================================================================== *
       * On quantity input, if the function returns a number, use it as the value   *
       * -------------------------------------------------------------------------- *
       * That means that the input would exceed the max and should not be applied   *
       * ========================================================================== */
      onInput(event: InputEvent, ean: string, quantity: number) {
        this.max_error = false
        // Prune input starts with multiple zeros
        if (Number((event.target as HTMLInputElement).value) === 0) this.b2b_quantity = 0
        // Check for maximum total
        const fallback_quantity = this.cart.setDish(ean, quantity, true, this.b2b)
        if (typeof fallback_quantity === 'number') {
          this.b2b_quantity = fallback_quantity
          this.max_error = true
        }
      },
    },
  })
</script>

<style scoped lang="pcss">
  .card {
    @apply h-full shadow-juit text-left;
    @apply bg-white;
    @apply flex sm-order:flex-col overflow-hidden;
    &.soldout {
      > a:after {
        content: '';
        @apply absolute w-full h-full bg-gray-700 opacity-70 z-1 top-0;
      }
    }
    > a {
      @apply relative flex-shrink-0 sm-order:w-auto;
      @apply mobile-sm:w-28 mobile-sm:h-full;
      &:before {
        content: '';
        height: 150%;
        @apply mobile-sm:h-full absolute w-screen sm:w-full z-10;
      }
      .soldout-info {
        max-width: 90%;
        @apply absolute font-bold transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-10;
        @apply text-white text-sm sm-order:text-xl lg:text-2xl text-center;
        .extra {
          @apply leading-tight text-xs font-normal sm-order:text-sm lg:text-base rounded-sm bg-white text-black px-2 py-0.5 md:px-4;
          @apply mt-2 sm-order:mt-3 md:mt-4 whitespace-pre md:whitespace-nowrap;
        }
        br {
          @apply sm-order:hidden;
        }
      }
    }
    .fav-button {
      @apply w-11 h-11 p-3 md:w-12 md:h-12 md:p-3.5 top-0 right-0 md:-right-0.5;
    }
    .dish-details {
      @apply w-full flex flex-col h-full relative;
      @apply px-2.5 py-2 xs:px-3 xs:pb-3 xl:px-4 xl:pt-3.5 xl:pb-4;
      .tags {
        @apply flex flex-wrap pb-1;
        .tiny:not(.yellow):not(.jade):not(.Special):not(.Fish):not(.Fisch):not(.Wagyu):not(.planted):not(.Spargel):not(.Asparagus):not(.Sweets):not(.XL):not(.Extra),
        .vegan + .vegetarian {
          @apply hidden;
        },
        span {
          @apply text-xs font-normal;
        }
      }
      .title {
        @apply leading-tight font-semibold text-base md:text-lg pr-6;
      }
      .subheadline {
        @apply text-xs sm:text-sm md:text-base mb-1 font-light;
      }
      .card-bottom {
        @apply relative flex-1 min-h-9 sm-order:min-h-12;
        .nutritions {
          @apply transform scale-80 sm-order:scale-100 origin-bottom-left flex flex-col text-xs xl:text-sm xxl:text-base;
          @apply mt-1 -mb-2 absolute bottom-0.5;
          div {
            line-height: 0.25rem;
            @apply h-4 pr-1 mr-1 xs:pr-1.5 xs:mr-1.5;
            @apply flex;
          }
        }
        .interactive-cart {
          @apply transform scale-90 xs:scale-100 origin-bottom-right;
          @apply cursor-pointer;
          @apply bg-blue text-white;
          @apply w-12 h-10 px-3 transition-all;
          @apply text-center text-3xl;
          @apply absolute z-20 bottom-0 right-0;
          &.disabled {
            @apply bg-gray-400;
          }
          .plus-action {
            @apply absolute -right-2 w-16 h-10 flex;
            .count {
              @apply absolute text-2xl right-16 -mr-2 text-black transition-all;
              @apply w-12 text-center bg-gray-200 h-10;
              line-height: 165%;
              &.hide {
                @apply w-0 opacity-0 pointer-events-none;
              }
            }
            .minus {
              @apply absolute w-12 bg-white border-gray-400 border text-black right-24 mr-2 h-10 transition-all;
              line-height: 112%;
              &.hide {
                @apply right-14 w-0 opacity-0 pointer-events-none;
              }
            }
            .plus {
              @apply self-center w-full relative font-mono sm:pb-0.5;
            }
          }
          .add-remove {
            @apply absolute w-0 h-10 right-0 top-0 opacity-0 transition-all;
            @apply overflow-hidden pointer-events-none;
            @apply z-50;
            @apply border border-blue bg-white text-blue;
            @apply flex justify-around;
            .button {
              @apply font-mono;
            }
            > span {
              @apply relative flex-grow pb-0.5;
              &.counter {
                @apply w-6 text-2xl mt-0.5 pt-0.5 pb-0 flex-grow-0;
              }
              &.disabled {
                @apply pointer-events-none text-gray-400;
              }
            }
          }
          &:hover {
            &.b2b {
              @apply w-36;
              .add-remove {
                @apply w-36 opacity-100;
                @apply pointer-events-auto;
                @apply select-none;
              }
            }
          }
        }
      }
    }

    &.b2b {
      @apply flex-row;
      > a {
        @apply !min-h-0 sm:w-32;
        &:before {
          @apply h-full z-0;
        }
      }
      .dish-details {
        .card-bottom {
          .interactive-cart {
            @apply md:w-36 shadow-none;
            .plus-action {
              @apply absolute -right-2 w-16 h-10 flex;
              .count {
                @apply right-auto w-full text-white w-16 bg-transparent;
                &.hide {
                  @apply hidden;
                }
              }
              .plus {
                @apply block;
                &.hide {
                  @apply hidden;
                }
              }
              .minus {
                @apply hidden;
              }
            }
            .add-remove {
              @apply md:w-36 opacity-100 pointer-events-auto z-10 border-none;
              .button {
                @apply bg-blue text-white;
                &.disabled {
                  @apply pointer-events-none bg-gray-400;
                }
              }
            }
            .display {
              @apply w-12 h-full text-2xl font-text leading-relaxed text-black bg-gray-100 border-t border-b border-gray-100;
              @apply absolute left-1/2 top-0 transform -translate-x-1/2 pointer-events-none z-1;
            }
            input {
              @apply relative w-12 text-2xl h-full font-text flex-grow-0 outline-none text-center bg-white p-0 z-10;
              @apply opacity-0 text-black bg-gray-100;
              margin-left: -2px;
              margin-right: -2px;
              &:focus {
                @apply opacity-100 border-l-transparent border-r-transparent;
                + .display {
                  @apply opacity-0;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
