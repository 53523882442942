<!-- eslint-disable vue/no-v-html -->
<!--==================================================================+
| VIEW: CHECKOUT                                                      |
+===================================================================-->
<template>
  <main class="checkout">
    <section class="checkout relative input-white">
      <juit-submitting class="overlay" :visible="busy" />
      <div class="container flex flex-col flex-wrap">
        <div v-if="load_page !== 'inactive'" class="text-top">
          <!--==================================================================+
          | PAGINATION                                                          |
          +===================================================================-->
          <div class="pagination">
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="step"
              :class="{ active: steps.indexOf(active_step) >= index }"
            >
              <span>
                <p>{{ index + 1 }}</p>
                <div>{{ $t( `checkout.step-${step}` ) }}</div>
              </span>
            </div>
          </div>
        </div>

        <!--==================================================================+
        | STEP 1 - LOGIN / REGISTER                                           |
        +===================================================================-->
        <div
          class="checkout-phase"
          :class="{ active: active_step === 'login' }"
        >
          <juit-login
            ref="login"
            class="accordion-content"
          />
        </div>

        <!--==================================================================+
        | STEP 2 - ADDRESSES                                                  |
        +===================================================================-->
        <div
          class="checkout-phase"
          :class="{ active: active_step === 'addresses' }"
        >
          <juit-address
            ref="address"
            class="accordion-content"
            :b2b="b2b"
            @ready="step_addresses_ready = $event"
            @step="active_step = $event"
          />
        </div>

        <!--==================================================================+
        | STEP 3 - SUMMARY                                                    |
        +===================================================================-->
        <div
          class="checkout-phase summary"
          :class="{ active: active_step === 'summary' }"
        >
          <juit-order-summary
            ref="summary"
            class="accordion-content"
            :b2b="b2b"
            :nordfrost="nordfrost"
            @step="active_step = $event"
            @stripe="stripe_checkout = $event"
          />
        </div>

        <!--==================================================================+
        | CTA BUTTON                                                          |
        +===================================================================-->
        <juit-button
          v-if="load_page !== 'inactive' && (!stripe_checkout || active_step === 'addresses')"
          class="md:mt-6 md:mx-auto"
          :disabled="!ctaAvailabilityCheck(active_step)"
          @click="ctaActions(active_step)"
        >
          {{ active_step === 'summary' ? nordfrost ? $t( 'checkout.ordernow' ) : $t( 'checkout.buynow' ) : $t( 'checkout.continue' ) }}
        </juit-button>

        <!--==================================================================+
        | STRIPE CHECKOUT                                                     |
        +===================================================================-->
        <div
          id="express-checkout-element"
          class="md:mt-6 min-h-12 w-full mx-auto md:w-auto"
          :class="stripe_checkout_class"
        />
      </div>
    </section>
  </main>
</template>

<script lang="ts">
  import juitAddress from '../components/checkout/address.vue'
  import juitOrderSummary from '../components/checkout/summary.vue'
  import juitSubmitting from '../widgets/juit-submitting.vue'
  import juitLogin from '../components/checkout/login.vue'
  import alertModal from '../modals/alert-modal.vue'
  import type { CreateAddress } from '@juitnow/api-addresses-v2'
  import { analyticsEvent, analyticsOrderItems } from '../analytics'
  import { defineComponent } from 'vue'
  import { ReactiveCart, reactiveCart, reactiveCheckout, boxOptionsB2B, boxOptions } from '../init/reactive-order'
  import { checkoutBusy, checkoutStep } from '../init/state'
  import { log } from '../init/log'
  import { checkoutQueue } from '../init/checkout-queue'
  import { applepay_support, stripe } from '../main'
  import { stripe_appearance, stripe_options } from '../init/stripe-checkout'
  import { locale } from '../init/i18n'
  import { env } from '../init/env'
  import { ConfirmPaymentData } from '@stripe/stripe-js'

  export default defineComponent({
    components: {
      juitSubmitting,
      juitAddress,
      juitOrderSummary,
      juitLogin,
    },

    data: (instance) => ({
      steps: [ 'login', 'addresses', 'summary' ],
      active_step: '',
      step_addresses_ready: false,
      create_addresses_from_session: [] as CreateAddress[],
      b2b: instance.$route.name === 'checkout-b2b',
      stripe_checkout: false,
      stripe_appearance,
      stripe_options,
    }),

    computed: {
      applepay() {
        return applepay_support
      },

      order_total(): number | undefined {
        return reactiveCheckout.total
      },

      cart(): ReactiveCart {
        return reactiveCart
      },

      nordfrost(): boolean {
        return this.b2b && this.cart.total >= boxOptionsB2B.slice(-1)[0]
      },

      /* ========================================================================== *
       * Ready to load the page components?                                         *
       * -------------------------------------------------------------------------- */
      load_page() {
        return checkoutQueue.loading_checkout_page
      },

      /* ========================================================================== *
       * Ready to check which step we are at?                                       *
       * -------------------------------------------------------------------------- */
      check_step() {
        return checkoutQueue.checking_step
      },

      /* ========================================================================== *
       * Ready to create an order?                                                  *
       * -------------------------------------------------------------------------- */
      step_summary_ready(): boolean {
        return Boolean(this.step_addresses_ready &&
          ((reactiveCheckout.payment && reactiveCheckout.slot?.uuid) || reactiveCheckout.nordfrost_week),
        )
      },

      /* ========================================================================== *
       * Show the loading overlay?                                                  *
       * -------------------------------------------------------------------------- */
      busy() {
        return Boolean(checkoutBusy.value.length || checkoutQueue.loading_checkout_page !== 'done')
      },

      stripe_checkout_class() {
        if (!this.stripe_checkout || this.active_step !== 'summary') return 'opacity-0'
        if (!this.step_summary_ready) return 'opacity-15 pointer-events-none'
        else return 'opacity-100'
      },
    },

    watch: {
      /* ========================================================================== *
       * Analytics                                                                  *
       * -------------------------------------------------------------------------- */
      active_step(new_step, old_step) {
        // GA Event: View shipping info
        if (new_step === 'addresses') {
          analyticsEvent('view_shipping_info', {})
        }

        // GA Event: View the delivery slots (on summary without slot)
        if (new_step === 'summary') {
          if (!reactiveCheckout.slot?.uuid) {
            analyticsEvent('view_delivery_window', {})
          }
          // GA Event: Add shipping addresses (from addresses to summary)
          if (old_step === 'addresses') {
            analyticsEvent('add_shipping_info', {
              items: analyticsOrderItems(this.cart.dishes),
            })
          }
        }
      },

      /* ========================================================================== *
       * After initializing, check if we still have a full cart                     *
       * -------------------------------------------------------------------------- */
      load_page: {
        handler(status) {
          this.$nextTick(async () => {
            if (status === 'inactive' || status === 'done') return
            if (!this.cart.box || (!this.b2b && !boxOptions.includes(this.cart.box))) return this.$router.push('/box') // No selected box or no b2c box on b2c checkout, go back to /box
            log(`%cCheckout page loaded. Box size ${this.cart.box}, dish count ${this.cart.total}`, 'color: magenta')
            if ((this.cart.total < this.cart.box) || (!this.b2b && this.cart.box !== this.cart.total)) { // Cart not full, go back to /order
              await this.$createModal(alertModal as any, {
                title: this.$t('checkout.not-available'),
                description: this.$t('checkout.dish-not-available'),
                url: this.b2b ? '/business/order' : '/order',
                locked: true,
              })
            } else checkoutQueue.loading_checkout_page = 'done'
          })
        },
        deep: true,
        immediate: true,
      },

      /* ========================================================================== *
       * When we have the final total of the order, init the Stripe web element     *
       * -------------------------------------------------------------------------- */
      async order_total(newValue, oldValue) {
        if (newValue !== oldValue && typeof newValue === 'number') await this.initStripeApplePay()
      },

      check_step(status) {
        if (status === 'pending') this.whichStep()
      },
    },

    async mounted() {
      if (this.check_step === 'pending' || this.check_step === 'done') this.whichStep()
    },

    methods: {
      /* ========================================================================== *
       * Init Stripe Web Checkout Elements                                          *
       * -------------------------------------------------------------------------- */
      async initStripeApplePay() {
        if (stripe && applepay_support) {
          const elements = stripe.elements({
            mode: 'payment',
            currency: 'eur',
            locale: locale.value,
            amount: this.order_total,
            appearance: this.stripe_appearance,
          })
          const expressCheckoutElement = elements.create('expressCheckout', this.stripe_options)
          expressCheckoutElement.mount('#express-checkout-element')

          expressCheckoutElement.on('confirm', async () => {
            const { error: submitError } = await elements.submit()
            if (submitError) return (this.$refs.summary as InstanceType<typeof juitOrderSummary>).resolveError(submitError)

            // Send the ConfirmationToken ID to your server for additional logic and attach the ConfirmationToken
            const order_uuid = await (this.$refs.summary as InstanceType<typeof juitOrderSummary>).submitOrder(true) as string
            if (!order_uuid) return (this.$refs.summary as InstanceType<typeof juitOrderSummary>).resolveError(null)
            const thankyou_url = `${env.VITE_BASE_URL}thankyou#${order_uuid}`

            // Create a ConfirmationToken using the details collected by the Express Checkout Element
            const { error, confirmationToken } = await stripe!.createConfirmationToken({ elements })
            if (error) return (this.$refs.summary as InstanceType<typeof juitOrderSummary>).resolveError(error)
            log('Stripe Confirmation Token', confirmationToken)

            // Create a payment
            const payment = await (this.$refs.summary as InstanceType<typeof juitOrderSummary>).createPayment(order_uuid, confirmationToken.id)
            log('Payment created', payment)

            // We are done here if payment is confirmed
            if (payment?.status === 'paid') return this.$router.push(`/thankyou#${order_uuid}`)

            // Otherwise, try to confirm the PaymentIntent via Stripe
            const { error: confirmError } = await stripe!.confirmPayment({
              clientSecret: payment?.client_secret as string,
              confirmParams: {
                confirmation_token: confirmationToken.id,
                return_url: thankyou_url,
              } as ConfirmPaymentData,
            })

            if (confirmError) return (this.$refs.summary as InstanceType<typeof juitOrderSummary>).resolveError(confirmError)
            else {
              // The payment UI automatically closes with a success animation.
              // Your customer is redirected to your `return_url`.
            }
          })
        }
      },

      /* ========================================================================== *
       * Switch to certain step base on checkout progress                           *
       * -------------------------------------------------------------------------- */
      whichStep() {
        log('%cChecking step', 'font-weight: bold')
        const requirements_general = reactiveCheckout.addresses.billing?.uuid && reactiveCheckout.addresses.shipping?.uuid
        const requirements_b2b = reactiveCheckout.addresses.billing?.company && reactiveCheckout.addresses.shipping?.company && reactiveCheckout.addresses.billing?.vat
        if (!this.user) this.active_step = 'login'
        else if ((!requirements_general) || (this.b2b && !requirements_b2b)) this.active_step = 'addresses'
        else this.active_step = 'summary'
        checkoutQueue.checking_step = 'done'
        checkoutStep.value = this.active_step
      },

      /* ========================================================================== *
       * CTA button availability check base on step                                 *
       * -------------------------------------------------------------------------- */
      ctaAvailabilityCheck(step: string) {
        switch (step) {
          case 'login': return reactiveCheckout.addresses.email
          case 'addresses': return this.step_addresses_ready
          case 'summary': return this.step_summary_ready
        }
      },

      /* ========================================================================== *
       * CTA button action base on step                                             *
       * -------------------------------------------------------------------------- */
      ctaActions(active_step: string) {
        if (active_step === 'login') (this.$refs.login as InstanceType<typeof juitLogin>).getUser()
        if (active_step === 'addresses') (this.$refs.address as InstanceType<typeof juitAddress>).createAddress()
        if (active_step === 'summary') (this.$refs.summary as InstanceType<typeof juitOrderSummary>).submitOrder()
      },
    },
  })
</script>

<style scoped lang="pcss">
  section.checkout {
    @apply w-auto;
    .text-top {
      @apply mb-8 md:mb-7 text-center;
      .pagination {
        @apply flex justify-center -mt-1 md:-mt-6 pb-2 md:pb-10 mx-auto max-w-2xl;
        .step {
          @apply block w-full relative mx-0.5;
          &:before {
            content: '';
            @apply block absolute;
            @apply w-full h-1.5 md:h-2;
            @apply bg-gray-200;
            @apply -bottom-4;
          }
          &:first-child:before {
            @apply rounded-l-sm;
          }
          &:last-child:before {
            @apply rounded-r-sm;
          }
          span {
            @apply mx-6 relative z-10;
            @apply w-7 h-7 md:w-9 md:h-9 mx-auto;
            @apply flex flex-col justify-center;
            @apply border border-greenUrbify bg-ash rounded-full border-dashed;
            @apply text-greenUrbify font-semibold;
            @apply cursor-default;
            p {
              @apply block;
            }
            div {
              @apply absolute;
              @apply top-12 md:top-14 left-1/2 transform -translate-x-1/2;
              @apply text-sm md:text-base text-black font-normal whitespace-nowrap;
            }
          }
          &.active {
            @apply block;
            &:before {
              @apply bg-greenUrbify;
            }
            span {
              @apply text-white bg-greenUrbify;
            }
          }
          &.disabled {
            @apply pointer-events-none;
            &:before {
              @apply bg-gray-400;
            }
            span {
              @apply border-gray-400 text-gray-400;
            }
            div {
              @apply text-gray-400;
            }
          }
        }
      }
    }
    .checkout-phase {
      @apply relative;
      @apply bg-white;
      @apply md:shadow-juit;
      @apply min-h-11 md:min-h-16 py-4 md:p-6 mb-2 md:mb-4 w-full;
      @apply hidden;

      .accordion-content {
        @apply pointer-events-none md:pointer-events-auto;
        @apply max-h-0 overflow-hidden transition-all duration-300;
      }
      &.active {
        @apply block;
        .accordion-content {
          @apply pointer-events-auto;
          @apply max-h-max overflow-visible;
        }
      }
      &.payment {
        @apply bg-transparent shadow-none;
        @apply pt-0 overflow-visible;
      }
    }
    h2 {
      @apply mt-0;
    }

    .overlay {
      @apply fixed h-screen z-40;
    }
  }

  ::v-deep(.checkout-phase .reset-password) {
    @apply mx-auto max-w-2xl;
  }
  ::v-deep(.checkout-phase .register) {
    @apply mx-auto max-w-2xl;
  }
</style>
