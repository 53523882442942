import { env } from '../init/env'
import { log } from '../init/log'
import { GAEvent, GAEvents, isAnalyticsEvent } from './google'

const trackPinterest = env.MODE === 'production' || window.location.host === 'www.juit.com'

/* ========================================================================== *
 * LOADING PINTEREST TAG                                                      *
 * ========================================================================== */

const loadedPinterestTags = new Set<string>()

export function loadPinterestTag(tagId: string): void {
  if (!trackPinterest) return log('Pinterest Tag disabled on dev')
  if (loadedPinterestTags.has(tagId)) return log(`Pinterest Tag ${tagId} already loaded`)

  log('Loading Pinterest Tag', tagId)
  const script = document.createElement('script')
  script.text = `
  !function(e){if(!window.pintrk){window.pintrk = function () {
    window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
      n=window.pintrk;n.queue=[],n.version="3.0";var
      t=document.createElement("script");t.async=!0,t.src=e;var
      r=document.getElementsByTagName("script")[0];
      r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
    pintrk('load', '${tagId}', {em: '<user_email_address>'});
    pintrk('page');
  `

  document.head.appendChild(script)

  const noscript = document.createElement('noscript')
  script.text = `
    <noscript>
      <img height="1" width="1" style="display:none;" alt=""
        src="https://ct.pinterest.com/v3/?event=init&tid=${tagId}&pd[em]=<hashed_email_address>&noscript=1" />
    </noscript>
  `

  document.body.appendChild(noscript)
  loadedPinterestTags.add(tagId)
}

declare global {
  interface Window {
    pintrk?<E extends keyof GAEvents>(
      method: 'track',
      event: string,
      params: GAEvents[E],
      extra?: { eventID: string },
    ): void
  }
}

export function pinterestEvent<K extends GAEvent>(event: K, params: GAEvents[K]): void {
  if (!trackPinterest) return

  // Conversion tracking, is only available for the 9 standard pinterest events. They are mapped as follows:
  //
  // * page_view         => pagevisit
  // * add_payment_info  => custom (event_id: addpaymentinfo)
  // * add_to_cart       => addtocart
  // * select_box        => custom (event_id: selectbox)
  // * begin_checkout    => custom (event_id: begincheckout)
  // * purchase          => checkout
  // * sign_up           => signup
  // * view_item         => viewcategory
  //
  // Unused events:
  // 'watchvideo', 'lead', 'search'

  if (isAnalyticsEvent('page_view', event, params)) {
    globalThis.window.pintrk?.('track', 'pagevisit', {
      event_id: 'pagevisit',
      property: params.page_location,
    })
  }

  if (isAnalyticsEvent('add_payment_info', event, params)) {
    globalThis.window.pintrk?.('track', 'custom', {
      event_id: 'addpaymentinfo',
      property: params.payment_type,
    })
  }


  if (isAnalyticsEvent('add_to_cart', event, params)) {
    globalThis.window.pintrk?.('track', 'addtocart', {
      event_id: 'addtocart',
      line_items: [ {
        product_name: params.items[0].item_name,
        product_quantity: params.items[0].quantity,
        product_id: params.items[0].item_id,
      } ],
      currency: params.currency,
    })
  }

  if (isAnalyticsEvent('select_box', event, params)) {
    globalThis.window.pintrk?.('track', 'custom', {
      event_id: 'selectbox',
      value: Number(params.box_size),
    })
  }


  if (isAnalyticsEvent('begin_checkout', event, params)) {
    globalThis.window.pintrk?.('track', 'custom', {
      event_id: 'selectbox',
      property: params.items,
    })
  }

  if (isAnalyticsEvent('purchase', event, params)) {
    globalThis.window.pintrk?.('track', 'checkout', {
      event_id: 'checkout',
      line_items: [
        params.items.map((item) => {
          return {
            product_name: item.item_name,
            product_quantity: item.quantity,
            product_id: params.items[0].item_id,
          }
        }),
      ],
      value: params.value,
      currency: params.currency,
    })
  }

  if (isAnalyticsEvent('sign_up', event, params)) {
    globalThis.window.pintrk?.('track', 'signup', {
      event_id: 'signup',
    })
  }

  if (isAnalyticsEvent('view_item', event, params)) {
    globalThis.window.pintrk?.('track', 'viewcategory', {
      event_id: 'viewcategory',
      line_items: [ {
        product_name: params.items[0].item_name,
        product_id: params.items[0].item_id,
      } ],
    })
  }
}
